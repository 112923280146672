








































































import { defineComponent } from "@vue/composition-api";
import StaseVM, { Stase } from "../models/stase";
import { setRefreshRequest } from "../modules/store";
import router from "@/router";
import { toUserDateFormat } from "@/apps/core/modules/utils/datetime";
import useViewModel from "@/apps/core/modules/useViewModel";
import { DialogProgrammatic as Dialog } from "buefy";
import { isAdmin } from "@/apps/accounts/modules/store";

export default defineComponent({
  name: "StaseDetail",
  components: {
    ContextMenu: () => import("@/apps/core/components/ContextMenu.vue"),
    DetailField: () => import("@/apps/core/components/DetailField.vue"),
    ResidenTable: () => import("@/apps/stase/components/ResidenTable.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const paramsId = router.currentRoute.params.id;
    const staseVM = new StaseVM();
    const { viewModelRef, instance } = useViewModel(paramsId, staseVM);
    const stase = instance as Stase;
    const menuConfigs = [
      {
        label: "Ubah",
        onClick: () =>
          router.push({
            name: `stase-change`,
            params: { id: paramsId },
          }),
      },
      {
        label: "Hapus",
        onClick: () => {
          Dialog.confirm({
            message: "Hapus aktivitas ini?",
            onConfirm: async () => {
              if (stase.id === null) return;
              await viewModelRef.api.delete(stase.id);
              setRefreshRequest("context-menu");
              router.go(-1);
            },
          });
        },
      },
    ];
    return {
      // Data
      isAdmin,
      menuConfigs,
      viewModelRef,
      stase,
      toUserDateFormat,
    };
  },
});
