import { API } from "@/apps/core/api";
import PlainModel from "@/apps/core/models/plainModel";
import ViewModel from "@/apps/core/models/viewModel";
import APP_CONFIG from "@/apps/core/modules/config";

class StaseResiden extends PlainModel {
  staseId: string | null = null;
  residen: Record<string, string> | null = null;
}


class StaseResidenAPI extends API {
  static modelPath = "/stase/residen/";

  constructor() {
    super(StaseResidenAPI.modelPath);
  }
}


class StaseResidenBatchAPI extends API {
  static modelPath = "/stase/";
  createUrl = `${APP_CONFIG.baseAPIURL}/stase/residen/batch-create/`;

  constructor() {
    super(StaseResidenBatchAPI.modelPath);
  }
}

export default class StaseResidenVM extends ViewModel {
  constructor() {
    super(new StaseResidenAPI(), new StaseResiden());
  }
}

export { StaseResiden, StaseResidenAPI, StaseResidenBatchAPI };
