import FormModel from "@/apps/core/models/formModel";
import { Stase, StaseAPI } from "./stase";
import { setRefreshRequest } from "../modules/store";
import { StaseResidenBatchAPI } from "./staseResiden";

export default class StaseFM extends FormModel {
  constructor(requiredFields: Array<string>, nonRequiredFields: Array<string>) {
    super(
      new StaseAPI(),
      new Stase(),
      requiredFields,
      nonRequiredFields,
      setRefreshRequest
    );
  }
}

export class StaseResidenFM extends FormModel {
  constructor(requiredFields: Array<string>, nonRequiredFields: Array<string>) {
    super(
      new StaseResidenBatchAPI(),
      new Stase(),
      requiredFields,
      nonRequiredFields
    );
  }

  // updateErrorMap(errorList: Array<Record<string, Array<string>>>) {
  //   /* digunakan untuk load error dari response */
  //   this.validity.validated = false;
  //   this.validity.edited = false;
  //   let errorMap = this.observables.errorMap;
  //   for (const key of Object.keys(errorMap)) {
  //     if (!Array.isArray(errorMap[key])) {  // ignore inlineError
  //       if (key in respErrorMap) {
  //         errorMap[key] = respErrorMap[key].join(". ");
  //       } else {
  //         errorMap[key] = "";
  //       }
  //     }
  //   }

  //   if (!has(respErrorMap, this.inlineModelListName)) return;

  //   let inlineErrorList = this.observables.errorMap.inlineErrorList;
  //   const inlineErrMap = inlineErrorList[0];
  //   for (const [idx, inlineErr] of respErrorMap[this.inlineModelListName].entries()) {
  //     for (const key of Object.keys(inlineErrMap)) {
  //       if (key in inlineErr) {
  //         inlineErrorList[idx][key] = inlineErr[key].join(". ");
  //       } else {
  //         inlineErrorList[idx][key] = "";
  //       }
  //     }
  //   }
  // }

  getPayload() {
    const payload = super.getPayload();
    const staseResidenSet = payload["staseresiden_set"] as Array<string>;
    payload["data_list"] = staseResidenSet.map((sres) => {
      return { stase: this.instance.id, residen: sres };
    });

    delete payload["staseresiden_set"];
    return payload;
  }
}
