










































































































import { defineComponent } from "@vue/composition-api";
import router from "@/router";
import StaseFM from "../models/staseForm";
import useFormModel from "@/apps/core/modules/useFormModel";
import { has } from "lodash";

/* Hanya create saja, tanpa updated, tidak perlu paramsId dari router */
export default defineComponent({
  name: "StaseForm",
  components: {
    DatePicker: () => import("@/apps/core/components/DatePicker.vue"),
    GenericAutocomplete: () =>
      import("@/apps/core/components/GenericAutocomplete.vue"),
    GenericSelect: () => import("@/apps/core/components/GenericSelect.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const isCreate = !has(router.currentRoute.params, "id");
    const requiredFields = [
      "angkatan",
      "subDivisi",
      "kompetensi",
      "mulai",
      "hingga",
    ];
    const form = new StaseFM(requiredFields, []);
    const composition = useFormModel(form, router.currentRoute.params.id);

    return {
      // Data
      isCreate,
      stase: composition.instance,

      // Composition
      ...composition,
    };
  },
});
